import React, { useState, useEffect } from "react";
import Logo from "../../Assets/xopyLogo.png";
import "../../Assets/Styles/Header.css";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleScrollTo = (id) => {
    gsap.to(window, { duration: 2.0, scrollTo: { y: `#${id}`, offsetY: 50 } });
    setIsMenuOpen(false);
  };

  // Detect scroll to change header background
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 flex justify-between items-center px-1 md:px-8 transition-colors duration-300 shadow-md ${
        isScrolled ? "bg-[#FDDA0D]" : "bg-white"
      }`}
    >
      {/* Logo Section */}
      <div className="flex items-center">
        <img src={Logo} alt="Company Logo" className="h-20 w-32 md:p-0" />
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="focus:outline-none">
          <svg
            className="w-8 h-8 text-black mr-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>
      </div>

      {/* Navigation Menu */}
      <nav
        className={`fixed top-0 left-0 w-full h-full  transform transition-transform duration-500 ease-in-out ${
          isMenuOpen ? "translate-x-0 bg-white" : "-translate-x-full"
        } md:static md:flex md:transform-none md:items-center md:justify-end z-40`}
      >
        {/* Close Button for Mobile Menu */}
        <div className="absolute top-4 right-4 md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg
              className="w-8 h-8 text-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Links */}
        <div className="flex flex-col md:flex-row items-center gap-8 p-6 md:p-0 h-full justify-center">
          <div
            onClick={() => handleScrollTo("features")}
            className="navlink text-2xl md:text-lg cursor-pointer"
          >
            Features
          </div>
          <Link to="/sign-in">
            <button
              className={`text-2xl md:text-lg px-5 py-2 rounded-md border-2 transition-all duration-300 ${
                isScrolled
                  ? "bg-black text-white hover:bg-gray-700"
                  : "bg-white text-black hover:bg-slate-100"
              }`}
            >
              Sign Up
            </button>
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
