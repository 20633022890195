import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollAnimation from "./LandingPage/scrollanimation";
import Login from "./OperatorPages/Login";
import PageNotFound from "./PageNotFound";
import RegistrationForm from "./OperatorPages/Registration";
import Dashboard from "./OperatorPages/Dashboard";
import Preferences from "./EndUser/Perferences";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ScrollAnimation />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/preferences" element={<Preferences />} />

        <Route path="/dashboard/:username" element={<Dashboard />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
