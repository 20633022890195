import React, { useEffect, useRef, useState } from "react";
import "tailwindcss/tailwind.css";
import image1 from "../Assets/image1.png";
import image2 from "../Assets/image2.png";
import image3 from "../Assets/image3.jpg";

import HeroSection from "../components/Layout/LandingPage/HeroSection";
import XopyFeatures from "../components/Layout/LandingPage/XopyFeatures";
import Team from "../components/Layout/LandingPage/Team";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Mission from "../components/Layout/LandingPage/Mission";
import { FaArrowUp } from "react-icons/fa";

const ScrollAnimation = () => {
  const containerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1024); // Assuming lg breakpoint is 1024px
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) return;

    class StickySections {
      constructor(containerElement) {
        this.container = {
          el: containerElement,
          height: 0,
          top: 0,
          bottom: 0,
        };
        this.sections = Array.from(
          this.container.el.querySelectorAll("section")
        );
        this.viewportTop = 0;
        this.activeIndex = 0;
        this.scrollValue = 0;
        this.onScroll = this.onScroll.bind(this);
        this.initContainer = this.initContainer.bind(this);
        this.handleSections = this.handleSections.bind(this);
        this.remapValue = this.remapValue.bind(this);
        this.init();
      }

      onScroll() {
        this.handleSections();
      }

      initContainer() {
        // Reduce the total scroll height
        this.container.el.style.setProperty(
          "--stick-items",
          `${(this.sections.length + 2) * 100}vh`
        );
        this.container.el.classList.add("[&_*]:!transition-none");
        setTimeout(() => {
          this.container.el.classList.remove("[&_*]:!transition-none");
        }, 1);
      }

      handleSections() {
        this.viewportTop = window.scrollY;
        this.container.height = this.container.el.clientHeight;
        this.container.top = this.container.el.offsetTop;
        this.container.bottom = this.container.top + this.container.height;

        if (this.container.bottom <= this.viewportTop) {
          this.scrollValue = this.sections.length;
        } else if (this.container.top >= this.viewportTop) {
          this.scrollValue = 0;
        } else {
          // Adjust the scroll range to make sections change more quickly
          this.scrollValue = this.remapValue(
            this.viewportTop,
            this.container.top,
            this.container.bottom,
            0,
            this.sections.length
          );
        }

        this.activeIndex = Math.floor(this.scrollValue);

        this.sections.forEach((section, i) => {
          if (i === this.activeIndex) {
            section.style.setProperty("--stick-visibility", "1");
            section.style.setProperty("--stick-scale", "1.3");
          } else {
            section.style.setProperty("--stick-visibility", "0");
            section.style.setProperty("--stick-scale", ".8");
          }
        });
      }

      remapValue(value, start1, end1, start2, end2) {
        const remapped =
          ((value - start1) * (end2 - start2)) / (end1 - start1) + start2;
        return Math.max(Math.min(remapped, end2), start2);
      }

      init() {
        this.initContainer();
        this.handleSections();
        window.addEventListener("scroll", this.onScroll);
      }
    }

    const stickySections = new StickySections(containerRef.current);

    return () => {
      window.removeEventListener("scroll", stickySections.onScroll);
    };
  }, [isMobile]);

  const sectionData = [
    {
      title: "A Cloud Based Platform",
      text: "Reduce human errors and interventions and say yes to a streamlined platform for facilitating safe and secure transfer.",
      image: image1,
    },
    {
      title: "Convenience",
      text: "The streamlined process reduces confusion and raises the convenience.",
      image: image2,
    },
    {
      title: "Secure and direct",
      text: "Adds an added layer of security to the traditional methods with added convenience and straightforward method.",
      image: image3,
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="relative font-inter antialiased">
      <main className="relative flex flex-col justify-center overflow-hidden supports-[overflow:clip]:overflow-clip ">
        <Header />
        <div className="md:mt-20 mt-20">
          <HeroSection />
        </div>
        <div className="md:mt-10">
          <Mission />
        </div>

        <div
          className={`w-full max-w-6xl mx-auto px-4 md:px-6 ${
            isMobile ? "py-12" : ""
          }`}
        >
          <div
            className={`max-w-md mx-auto lg:max-w-none ${
              isMobile ? "" : "md:min-h-[var(--stick-items)]"
            }`}
            ref={containerRef}
          >
            <div
              className={`${
                isMobile
                  ? "space-y-16"
                  : "lg:sticky lg:top-0 md:h-screen space-y-16 lg:space-y-0"
              }`}
            >
              {sectionData.map((section, index) => (
                <section
                  key={index}
                  className={`${
                    isMobile
                      ? ""
                      : "lg:absolute lg:inset-0 lg:z-[var(--stick-visibility)]"
                  }`}
                >
                  <div
                    className={`flex ${
                      isMobile ? "flex-col" : "flex-col lg:h-full lg:flex-row"
                    } space-y-4 space-y-reverse lg:space-y-0 lg:space-x-20`}
                  >
                    <div
                      className={`flex-1 flex items-center ${
                        isMobile
                          ? ""
                          : "lg:opacity-[var(--stick-visibility)] transition-opacity duration-300"
                      } order-1 lg:order-none`}
                    >
                      <div className="space-y-3">
                        <div className="relative inline-flex text-3xl font-bold text-grey-500 curved-underline">
                          {section.title}
                          <svg
                            className="fill-indigo-300 absolute top-full w-full"
                            xmlns="http://www.w3.org/2000/svg"
                            width="166"
                            height="4"
                          ></svg>
                        </div>
                        <p className="text-lg text-gray-900 text-pretty">
                          {section.text}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`flex-1 flex items-center ${
                        isMobile
                          ? ""
                          : "lg:scale-[var(--stick-scale)] lg:opacity-[var(--stick-visibility)] transition duration-300"
                      }`}
                    >
                      <img
                        src={section.image}
                        alt={`Illustration 0${index + 1}`}
                        className="border-2 border-gray-500 pos rounded-[30px] h-auto w-full max-w-[500px] mx-auto"
                      />
                    </div>
                  </div>
                </section>
              ))}
            </div>
          </div>
        </div>
      </main>
      <XopyFeatures />
      <Team />
      <Footer />

      <button
        onClick={scrollToTop}
        className="fixed bottom-4 left-4 p-2 text-black text-2xl font-extrabold border-2 rounded-full  duration-300 ease-in-out transform hover:scale-105"
        aria-label="Scroll to Top"
      >
        <FaArrowUp className="text-lg"/> 
      </button>
    </div>
  );
};

export default ScrollAnimation;
