import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Menu, X, ChevronRight, LogIn, LogOut } from "lucide-react";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { username } = useParams();
  const navigate = useNavigate();

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleAuth = () => {
    setIsLoggedIn(!isLoggedIn);
    if (isLoggedIn) {
      // If logging out, redirect to home page
      navigate("/");
    }
  };

  const menuItems = [
    { name: "Dashboard", icon: <ChevronRight size={20} /> },
    { name: "Profile", icon: <ChevronRight size={20} /> },
    { name: "Settings", icon: <ChevronRight size={20} /> },
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div
        className={`bg-white ${
          isSidebarOpen ? "w-64" : "w-20"
        } transition-all duration-300 ease-in-out`}
      >
        <div className="flex justify-between items-center p-4">
          <h2
            className={`text-xl font-semibold ${isSidebarOpen ? "" : "hidden"}`}
          >
            Menu
          </h2>
          <button
            onClick={toggleSidebar}
            className="p-1 rounded-full hover:bg-gray-200"
          >
            {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        <nav>
          {menuItems.map((item, index) => (
            <a
              key={index}
              href="/home"
              className="flex items-center p-4 hover:bg-gray-100"
            >
              {item.icon}
              <span className={`ml-4 ${isSidebarOpen ? "" : "hidden"}`}>
                {item.name}
              </span>
            </a>
          ))}
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Navbar */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              <h1 className="text-lg font-semibold">
                Welcome, {decodeURIComponent(username)}
              </h1>
              <button
                onClick={handleAuth}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isLoggedIn ? (
                  <>
                    <LogOut size={20} className="mr-2" /> Logout
                  </>
                ) : (
                  <>
                    <LogIn size={20} className="mr-2" /> Login
                  </>
                )}
              </button>
            </div>
          </div>
        </header>

        {/* Page content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 flex items-center justify-center">
                <p className="text-gray-500">Your page content goes here</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
