import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../Assets/xopyLogo.png";
import { signInWithGooglePopup } from "../utils/firebase.utils";

const Signup = () => {
  const navigate = useNavigate();

  const handleEmailSignIn = (e) => {
    e.preventDefault();
    // Placeholder for future email/password sign-in implementation
    toast.info("Email/password sign-in not implemented yet.");
  };

  const logGoogleUser = async () => {
    try {
      const result = await signInWithGooglePopup();
      const user = result.user;
      toast.success("Signed in with Google successfully!");
      navigate(`/dashboard/${encodeURIComponent(user.displayName || "user")}`);
    } catch (error) {
      toast.error("Failed to sign in with Google: " + error.message);
    }
  };

  return (
    <div className="font-[sans-serif] relative">
      <div className="min-h-screen flex fle-col items-center justify-center py-6 md:px-4 px-2 border-2">
        <div className="absolute top-0 left-0 right-0">
          <img src={Logo} alt="logo" className="w-32 ml-10 mt-2" />
        </div>
        <div className="grid md:grid-cols-2 items-center gap-10 max-w-6xl w-full md:border-2  md:rounded-3xl md:border-blue-400 p-16">
          <div>
            <h2 className="lg:text-5xl text-3xl font-extrabold lg:leading-[50px] text-gray-800">
              Seamless Login for Exclusive Access
            </h2>
            <p className="text-sm mt-6 text-gray-800">
              Immerse yourself in a hassle-free login journey with our
              intuitively designed login form. Effortlessly access your account.
            </p>
            <p className="text-sm mt-12 text-gray-800">
              Don't have an account{" "}
              <Link to={"/register"}>
                <p className="text-blue-600 font-semibold hover:underline ml-1 inline">
                  Register here
                </p>
              </Link>
            </p>
          </div>
          <form
            onSubmit={handleEmailSignIn}
            className="max-w-md md:ml-auto w-full"
          >
            <h3 className="text-gray-800 text-3xl font-extrabold mb-8">
              Sign in
            </h3>
            <div className="space-y-4">
              <div>
                <input
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="bg-gray-100 w-full text-sm text-gray-800 px-4 py-3.5 rounded-md outline-blue-600 focus:bg-transparent"
                  placeholder="Email address"
                />
              </div>
              <div>
                <input
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="bg-gray-100 w-full text-sm text-gray-800 px-4 py-3.5 rounded-md outline-blue-600 focus:bg-transparent"
                  placeholder="Password"
                />
              </div>
              <div className="flex flex-wrap items-center justify-between gap-4">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm text-gray-800"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <a
                    href="/forgetPassword"
                    className="text-blue-600 hover:text-blue-500 font-semibold"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
            </div>
            <div className="!mt-8">
              <button
                type="submit"
                className="w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Log in
              </button>
            </div>
            <div className="space-x-6 flex justify-center mt-8">
              <button
                onClick={logGoogleUser}
                type="button"
                className="border-none outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32px"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#fbbd00"
                    d="M120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308H52.823C18.568 144.703 0 198.922 0 256s18.568 111.297 52.823 155.785h86.308v-86.308C126.989 305.13 120 281.367 120 256z"
                    data-original="#fbbd00"
                  />
                  <path
                    fill="#0f9d58"
                    d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216C305.044 385.147 281.181 392 256 392z"
                    data-original="#0f9d58"
                  />
                  <path
                    fill="#31aa52"
                    d="m139.131 325.477-86.308 86.308a260.085 260.085 0 0 0 22.158 25.235C123.333 485.371 187.62 512 256 512V392c-49.624 0-93.117-26.72-116.869-66.523z"
                    data-original="#31aa52"
                  />
                  <path
                    fill="#3c79e6"
                    d="M512 256a258.24 258.24 0 0 0-4.192-46.377l-2.251-12.299H256v120h121.452a135.385 135.385 0 0 1-51.884 55.638l86.216 86.216a260.085 260.085 0 0 0 25.235-22.158C485.371 388.667 512 324.38 512 256z"
                    data-original="#3c79e6"
                  />
                  <path
                    fill="#cf2d48"
                    d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606C388.668 26.629 324.381 0 256 0l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z"
                    data-original="#cf2d48"
                  />
                  <path
                    fill="#eb4132"
                    d="M256 120V0C187.62 0 123.333 26.629 74.98 74.98a259.849 259.849 0 0 0-22.158 25.235l86.308 86.308C162.883 146.72 206.376 120 256 120z"
                    data-original="#eb4132"
                  />
                </svg>
              </button>
              {/* Facebook and Apple buttons removed for brevity */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
