import React from "react";
import image1 from "../../../Assets/XopyFeatures/cloud.png";
import image2 from "../../../Assets/XopyFeatures/mobile-access.png";
import image3 from "../../../Assets/XopyFeatures/data-exchange.png";
import image4 from "../../../Assets/XopyFeatures/statistics.png";
import image5 from "../../../Assets/XopyFeatures/unlimited.png";
import image6 from "../../../Assets/XopyFeatures/no-fee.png";


const XopyFeatures = () => {
  return (
    <section id="features">
      <div className="w-full bg-gray-100 py-16 flex flex-col items-center justify-center">
        <div className="mb-8 px-4">
          <h1 className="text-3xl md:text-4xl font-bold text-center">
            Why Choose Xopy?
          </h1>
          <p className="mt-4 text-sm md:text-lg text-gray-600 font-base text-center">
            Xopy offers unique features to make file sharing easy, secure, and
            efficient.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 w-full max-w-6xl px-4">
          <Feature
            icon={image1}
            title="Secure Uploads"
            description="All files are encrypted during upload and transfer to protect your data."
          />
          <Feature
            icon={image6}
            title="Instant Sharing"
            description="Share files instantly with a simple link, no account required."
          />
          <Feature
            icon={image5}
            title="Unlimited Uploads"
            description="Store and share as many files as you need, with no storage limits."
          />
          <Feature
            icon={image2}
            title="Mobile Access"
            description="Access your files from anywhere, on any device, with our mobile-friendly interface."
          />
          <Feature
            icon={image4}
            title="Advanced Analytics"
            description="Track file activity and usage with detailed analytics and reporting."
          />
          <Feature
            icon={image3}
            title="Seamless File Sharing"
            description="Seamless file sharing without any hassle, simple and secure."
          />
        </div>
      </div>
    </section>
  );
};

const Feature = ({ icon, title, description }) => (
  <div className="flex flex-col items-center text-center">
    <img
      src={icon}
      alt={title}
      className="w-12 h-12 md:w-16 md:h-16 mb-2 md:mb-4 hover:scale-110 transition-all duration-300"
    />
    <h3 className="text-sm md:text-xl font-bold mb-1 md:mb-2">{title}</h3>
    <hr class="md:w-56 w-28 h-px mx-auto bg-black border-0 my-2 md:my-3"></hr>
    <p className="text-xs md:text-sm">{description}</p>
  </div>
);

export default XopyFeatures;
