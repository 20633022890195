import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { User, Mail, Lock, CheckCircle, Edit2 } from "lucide-react";
import Logo from "../Assets/xopyLogo.png";

function RegistrationForm() {
  const [formValues, setFormValues] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    usernameError: false,
    emailError: false,
    passwordError: false,
    confirmPasswordError: false,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
    setFormErrors({ ...formErrors, [`${id}Error`]: false });
  };

  const validateForm = () => {
    let isValid = true;
    const { username, email, password, confirmPassword } = formValues;
    const newErrors = {
      usernameError: false,
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
    };

    if (!username) {
      newErrors.usernameError = true;
      isValid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailPattern.test(email)) {
      newErrors.emailError = true;
      isValid = false;
    }

    if (!password) {
      newErrors.passwordError = true;
      isValid = false;
    }

    if (password !== confirmPassword) {
      newErrors.confirmPasswordError = true;
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setCurrentStep(5); // Move to success state
    }
  };

  const iconVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const formVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const successVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: { type: "spring", stiffness: 260, damping: 20 },
    },
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <motion.div
            key="username"
            variants={formVariants}
            initial="hidden"
            animate="visible"
          >
            <label
              htmlFor="username"
              className="block text-gray-700 font-semibold mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your username"
              value={formValues.username}
              onChange={handleInputChange}
              required
            />
            {formErrors.usernameError && (
              <p className="text-red-500 text-sm mt-2">Username is required.</p>
            )}
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            key="email"
            variants={formVariants}
            initial="hidden"
            animate="visible"
          >
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your email"
              value={formValues.email}
              onChange={handleInputChange}
              required
            />
            {formErrors.emailError && (
              <p className="text-red-500 text-sm mt-2">
                Please enter a valid email.
              </p>
            )}
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="password"
            variants={formVariants}
            initial="hidden"
            animate="visible"
          >
            <label
              htmlFor="password"
              className="block text-gray-700 font-semibold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your password"
              value={formValues.password}
              onChange={handleInputChange}
              required
            />
            {formErrors.passwordError && (
              <p className="text-red-500 text-sm mt-2">Password is required.</p>
            )}
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            key="confirmPassword"
            variants={formVariants}
            initial="hidden"
            animate="visible"
          >
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 font-semibold mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Confirm your password"
              value={formValues.confirmPassword}
              onChange={handleInputChange}
              required
            />
            {formErrors.confirmPasswordError && (
              <p className="text-red-500 text-sm mt-2">
                Passwords do not match.
              </p>
            )}
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            key="review"
            variants={formVariants}
            initial="hidden"
            animate="visible"
          >
            <h3 className="text-xl font-semibold mb-4">
              Review Your Information
            </h3>
            {Object.entries(formValues).map(([key, value]) => (
              <div key={key} className="mb-4">
                <div className="flex justify-between items-center">
                  <span className="font-semibold capitalize">{key}:</span>
                  <button
                    onClick={() =>
                      setCurrentStep(
                        [
                          "username",
                          "email",
                          "password",
                          "confirmPassword",
                        ].indexOf(key)
                      )
                    }
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                </div>
                <p className="mt-1">
                  {key.includes("password") ? "••••••••" : value}
                </p>
              </div>
            ))}
          </motion.div>
        );
      case 5:
        return (
          <motion.div
            key="success"
            className="text-center"
            variants={successVariants}
            initial="hidden"
            animate="visible"
          >
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-green-500 mb-2">
              Registration Successful!
            </h3>
            <p className="text-gray-600">
              Welcome aboard! Your account has been created.
            </p>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 relative">
      <div className="absolute top-0 left-0 right-0">
        <img src={Logo} alt="logo" className="w-32 ml-10 mt-2" />
      </div>
      <div className="bg-white rounded-lg shadow-2xl p-8 max-w-md w-full">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Create an Account
        </h2>
        <div className="flex justify-center mb-8">
          {[User, Mail, Lock, CheckCircle, CheckCircle].map((Icon, index) => (
            <motion.div
              key={index}
              className={`w-12 h-12 mx-2 rounded-full flex items-center justify-center ${
                index <= currentStep ? "bg-blue-500" : "bg-gray-300"
              }`}
              variants={iconVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: index * 0.2 }}
            >
              <Icon className="w-6 h-6 text-white" />
            </motion.div>
          ))}
        </div>
        <form onSubmit={handleSubmit} noValidate>
          {renderStep()}
          {currentStep < 5 && (
            <motion.div
              className="mt-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {currentStep < 4 ? (
                <button
                  type="button"
                  onClick={() => setCurrentStep((prev) => prev + 1)}
                  className="w-full bg-blue-500 text-white py-2 rounded-lg font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white py-2 rounded-lg font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                >
                  Register
                </button>
              )}
              {currentStep > 0 && currentStep < 4 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  className="w-full mt-2 bg-gray-200 text-gray-700 py-2 rounded-lg font-semibold hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                >
                  Back
                </button>
              )}
            </motion.div>
          )}
        </form>
        <motion.p
          className="text-center text-gray-600 mt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          Already have an account?{" "}
          <Link to="/sign-in">
            <span className="text-blue-500 font-semibold inline">Sign In</span>
          </Link>
        </motion.p>
      </div>
    </div>
  );
}

export default RegistrationForm;
